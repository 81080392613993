<template>
	<v-card>
		<v-card-title>
			 <slot name="title"></slot>
		</v-card-title>
		<v-divider></v-divider>
		<v-list flat class="pa-0" three-line v-if="projects.length">
			<v-list-item-group v-for="project in projects" :key="project.id">
				<v-list-item :disabled="isProjectDisabled(project.id)" class="px-6">
					<v-list-item-content>
						<v-list-item-title>{{project.name}}</v-list-item-title>
						<v-list-item-subtitle><strong class="mr-2">建筑商:</strong>{{project.builder_name}}</v-list-item-subtitle>
						<v-list-item-subtitle><strong class="mr-2">项目开始日期:</strong>{{project.start_date}}</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn text color="success" :disabled="isProjectDisabled(project.id)" @click.stop="$emit('on-project-select', project)">
							添加
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<v-divider></v-divider>
			</v-list-item-group>
		</v-list>
		<v-card-text v-else>
			<div class="pt-4">
				暂时没有任何项目指派给您。
			</div>
		</v-card-text>
		<v-card-actions>
			<template v-if="totalPages > 1">
				<v-btn
					text
					@click="previousPage"
					:disabled="prevPageBtnState"
				>上一页</v-btn>
				<v-btn
					text
					@click="nextPage"
					:disabled="nextPageBtnState"
				>下一页</v-btn>
			</template>
			<v-spacer></v-spacer>
			<slot name="actions"></slot>
		</v-card-actions>
	</v-card>
</template>

<script>
import ProjectServices from '../../services/Project';

export default {
	name: 'ProjectAssignedList',
	data: () => ({
		projects: [],
		itemsPerPage: 15,
		totalPages: 1,
		currentPage: 1,
		status: 0,
	}),
	props: {
		existingProjectIds: {
			type: Array,
			required: false,
		}
	},
	watch: {

	},
	computed: {
		nextPageBtnState(){
			return this.currentPage < this.totalPages ? false : true;
		},
		prevPageBtnState(){
			return this.currentPage > 1 ? false : true;
		}
	},
	created(){
		this.getAssignedProjectList(1,15,0);
	},
	methods: {
		async getAssignedProjectList(){
			this.loading = true
			try {
				let response = await ProjectServices.getAssignedProjectList(this.currentPage, this.itemsPerPage, this.status)
					if(response.data.statusCode === 200){
						this.projects = response.data.data.items;
						this.itemsPerPage = response.data.data.max_items_per_page;
						this.totalPages = response.data.data.total_pages;
						this.currentPage = response.data.data.current_page;
						this.loading = false;
					}
				
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		isProjectDisabled(projectId){
			if(this.existingProjectIds){
				return this.existingProjectIds.indexOf(projectId) < 0 ? false : true;
			} else {
				return false;
			}
		},
		nextPage(){
			if(this.currentPage < this.totalPages){
				this.currentPage++;
				this.getAssignedProjectList()
			}
		},
		previousPage(){
			if(this.currentPage > 1){
				this.currentPage--;
				this.getAssignedProjectList()
			}
		}
	}
}
</script>
